export const en = {
  commons: {
    sorting: "Sort",
    false: "No",
    true: "Yes",
    logout: "Logout",
    show: "Show all",
    add: "Add new",
    view: "View",
    close: "Close",
    create: "Create",
    save: "Save",
    actions: "Actions",
    loading: "Loading...",
    from: "From",
    to: "To",
    goHome: "Go to homepage",
    goBack: "Go back",
    confirmTitle: "Confirm to proceed",
    none: "N/D",
    min: "Minimum",
    max: "Maximum",
    languages: {
      it: "Italian",
      en: "English"
    },
    address: {
      label: "Address",
      inputPlaceholder: "Enter the address"
    },
    latitude: "Latitude",
    longitude: "Longitude",
    altitude: "Altitude (meters)",
    alerts: {
      delete: "Deleting a resource is irreversible, proceed anyway?"
    },
    errors: {
      validation: "Solve the following errors...",
      global: "We are sorry, an error occurred.",
      "404": "This page doesn't exists",
      dateOrTimeRange: "Start date must be greater or equals to end date",
      dateOrTimeInTheFuture: "Start date must be a past date",
      fromDate: {
        gtEndDate: "Start date greater than end date"
      },
      toDate: {
        ltStartDate: "End date less than start date"
      },
      geolocation: {
        denied: "Please, authorize the geolocation functionality to get your current location",
        generic: "Geolocation failed, we couldn't get your current location, please try again later "
      }
    },
    resetZoom: "Resetta zoom",
    durations: {
      hours: "Hours",
      minutes: "Minutes",
      seconds: "Seconds",
      milliseconds: "Milliseconds"
    },
    buttons: {
      back_to_list: "Torna alla lista"
    },
    selectAll: "Select all",
    deselectAll: "Remove all",
    copyToClipboard: {
      btn: "Copy text",
      success: "Text copied to the clipboard",
      error: "Text was not copied to the clipboard"
    },
    sampleRate: "Sample rate",
    acquisitionRate: "Acquisition rate",
    port: "Port",
    type: "Connection",
    isDirty: "Warning! Your changes will be lost, do you want to continue?",
    createdBy: {
      label: "Created By",
      tableHeader: "$t(createdBy.label)",
      filterInputPlaceholder: "Filter by creator username"
    },
    createdAt: {
      label: "Created At",
      tableHeader: "$t(createdAt.label)",
      filterInputPlaceholder: "Filter by creation date"
    },
    more: "Show more",
    less: "Show less"
  },
  interface: {
    sidebar: {
      menus: {
        main: {
          label: "Main",
          home: "Homepage",
          dashboard: "Dashboard",
          fields: "Fields",
          devices: "Devices",
          wirelessDevices: "Wireless devices",
          sensors: "Sensors",
          firmwares: "Firmwares",
          evapotranspiration: "Evapotranspiration",
          notifications: "Notifications"
        },
        management: {
          label: "Management",
          cultures: "Cultures",
          fields: "$t(sidebar.menus.main.fields)"
        },
        definitions: {
          label: "Definitions",
          groups: "Field's groups",
          "culture-types": "Culture types",
          "field-types": "Management type",
          granulometries: "Granulometries",
          "irrigation-methods": "Irrigation methods",
          "irrigation-sources": "Irrigation sources",
          "coverage-types": "Site types",
          devices: "Devices",
          variables: "Variables",
          sensors: "Sensors",
          cultures: "Agea's cultures",
          varieties: "Agea's varieties"
        },
        administration: {
          label: "Administration",
          generalSettings: "Settings",
          users: "Users",
          tenants: "Tenant",
          scheduled: "Jobs",
          "api-keys": "Api keys",
          profile: "Business profile",
          companies: "Business profiles"
        }
      },
      footer: {}
    },
    topbar: {
      profile: {
        items: {
          profile: "Business Profile",
          notifications: "Notifications",
          password: "Change password",
          settings: "Settings"
        }
      }
    },
    crud: {
      table: {
        title: "List of",
        empty: "No data found",
        toolbar: {
          toggler: "Visible columns",
          export: {
            label: "Export",
            selectedLabel: "Selected",
            modalHeader: "Select the desired format",
            choices: {
              all: "All",
              currentPage: "Current Page"
            }
          },
          delete: "Delete"
        },
        filters: {
          formTitle: "Available filters",
          placeholder: "Filter by {{columnId}}",
          submitBtn: "Search",
          removeBtn: "Remove filters",
          toggler: {
            visible: "Hide filters",
            hidden: "Show filters"
          }
        },
        footer: {
          select: {
            label: "Show {{rowsCount}} rows"
          },
          pagination: {
            label: "Page {{current}} of {{total}}",
            manual: {
              label: "Change page",
              go: "Go",
              placeholder: "Page number...",
              error: "Insert a value between 1 and {{pageCount}}"
            }
          }
        },
        show: "Show {{resource}}",
        edit: "Edit {{resource}}",
        delete: "Delete {{resource}}"
      },
      form: {
        title: {
          create: "Create a new {{resource}}",
          update: "Update",
          show: "Back to list"
        },
        fields: {
          placeholder: "Enter the {{placeholderLabel}}"
        },
        reset: "Reset form"
      },
      export: {
        timeseries: {
          label: "Data export",
          submit: "Export data",
          fields: {
            label: "Select fields to export",
            placeholder: "Fields"
          },
          format: {
            label: "Choose data format"
          }
        },
        data: {
          success: "Table's data were successfully exported",
          error: "Could not export data from the table"
        }
      }
    },
    chart: {
      sensor: "Sensor",
      variable: "Measured variabe",
      last: "Last measured value",
      min: "$t(min)",
      max: "$t(max)",
      minMaxCount: "Min : Max | Sample num.",
      minMaxAvgCount: "Min : Max | Avg | N. campioni",
      avg: "Average",
      count: "Sample's number",
      missingUoM: "unknown U.o.M."
    },
    select: {
      noOptions: "No available options"
    },
    timeseries: {
      label: "Show data",
      refresh: "Refresh data"
    },
    backToDashboard: "Go to dashboard"
  },
  resources: {
    "api-keys": {
      label: "api key",
      label_other: "Api keys",
      createLabel: "New api key",
      regenerate: "Regenerate api key",
      verify: "Verify api key",
      enable: "Enable key",
      enable_other: "Enable keys",
      disable: "Disable key",
      disable_other: "Disable keys",
      delete: "Delete key",
      delete_other: "Delete keys",
      tenant: {
        label: "Tenant",
        name: {
          label: "$t(api-keys.tenant.label)",
          tableHeader: "$t(api-keys.tenant.label)",
          filterInputPlaceholder: "Filter by tenant"
        },
        formLabel: "$t(api-keys.tenant.label)",
        inputPlaceholder: "Select customer tenant"
      },
      clientDomain: {
        label: "Client URL",
        tableHeader: "$t(api-keys.clientDomain.label)",
        formLabel: "$t(api-keys.clientDomain.label)",
        inputPlaceholder: "Enter the client URL that will use the api key"
      },
      enabled: {
        label: "State",
        tableHeader: "$t(api-keys.enabled.label)"
      },
      verified: {
        label: "Verified",
        tableHeader: "$t(api-keys.verified.label)"
      },
      createdAt: {
        label: "Generated at",
        tableHeader: "$t(api-keys.createdAt.label)"
      },
      updatedAt: {
        label: "Update at",
        tableHeader: "$t(api-keys.updatedAt.label)"
      },
      publicKey: {
        label: "Api key",
        tableHeader: "$t(api-keys.publcKey.label)"
      }
    },
    users: {
      label: "user",
      label_other: "users",
      createLabel: "Create a new $t(users.label)",
      updateLabel: "Update $t(users.label)",
      resetAccountLabel: "Reset account credentials",
      resetAccountButton: "Reset",
      resetAccountAlert: "This operation will change the user login credentials, proceed anyway ?",
      firstName: {
        label: "Name",
        tableHeader: "$t(users.firstName.label)",
        formLabel: "$t(users.firstName.label)",
        inputPlaceholder: "Enter the name",
        filterInputPlaceholder: "Filter by name"
      },
      lastName: {
        label: "Last Name",
        tableHeader: "$t(users.lastName.label)",
        formLabel: "$t(users.lastName.label)",
        inputPlaceholder: "Enter the last name",
        filterInputPlaceholder: "Filter by last name"
      },
      email: {
        label: "Email",
        tableHeader: "$t(users.email.label)",
        formLabel: "$t(users.email.label)",
        inputPlaceholder: "Enter the email",
        filterInputPlaceholder: "Filter by email"
      },
      username: {
        label: "Username",
        tableHeader: "$t(users.username.label)",
        formLabel: "$t(users.username.label)",
        inputPlaceholder: "Enter the username",
        filterInputPlaceholder: "Filter by username"
      },
      tenant: {
        name: {
          label: "Tenant",
          tableHeader: "$t(users.tenant.name.label)",
          filterInputPlaceholder: "Filter by tenant"
        },
        formLabel: "$t(users.tenant.name.label)",
        inputPlaceholder: "Add to tenant"
      },
      roles: {
        name: {
          label: "Roles",
          tableHeader: "$t(users.roles.name.label)",
          filterInputPlaceholder: "Filter by role"
        },
        formLabel: "$t(users.roles.name.label)",
        inputPlaceholder: "Enter the roles"
      },
      enabled: {
        label: "Enabled",
        tableHeader: "Status",
        formLabel: "$t(users.enabled.label)",
        inputPlaceholder: "User is enabled",
        filterInputPlaceholder: "Filter by status",
        true: "Enabled",
        false: "Disabled"
      },
      updatedBy: {
        label: "Updated By",
        tableHeader: "$t(users.updatedBy.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by user"
      },
      updatedAt: {
        label: "Updated At",
        tableHeader: "$t(users.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    profile: {
      label: "profile",
      label_other: "profile"
    },
    credentials: {
      label: "profile",
      createLabel: "",
      updateLabel: "Change password",
      password: {
        label: "Password",
        tableHeader: "",
        formLabel: "$t(credentials.password.label)",
        inputPlaceholder: "Insert the new password",
        filterInputPlaceholder: ""
      },
      confirmPassword: {
        label: "Confirm password",
        tableHeader: "",
        formLabel: "$t(credentials.confirmPassword.label)",
        inputPlaceholder: "Insert confirmation password",
        filterInputPlaceholder: ""
      }
    },
    settings: {
      label: "Genaral settings",
      createLabel: " $t(settings.label)",
      updateLabel: " $t(settings.label)",
      submit: {
        success: "Settings successfully saved"
      },
      emailSettings: {
        fieldSetLabel: "Configure mail server",
        host: {
          label: "Host",
          formLabel: "Host",
          inputPlaceholder: "SMTP server host"
        },
        port: {
          label: "Port",
          formLabel: "Port",
          inputPlaceholder: "SMTP server port"
        },
        senderEmail: {
          label: "Sender email",
          formLabel: "Sender email",
          inputPlaceholder: "Sender email address"
        },
        senderDisplayName: {
          label: "Sender name",
          formLabel: "Sender name",
          inputPlaceholder: "Enter the sender name"
        },
        username: {
          label: "Username",
          formLabel: "Username",
          inputPlaceholder: "Username"
        },
        password: {
          label: "Password",
          formLabel: "Password",
          inputPlaceholder: "Password"
        },
        enableSSL: {
          label: "SSL",
          formLabel: "",
          inputPlaceholder: "Enable SSL"
        },
        enableStartTLS: {
          label: "StartTLS",
          formLabel: "",
          inputPlaceholder: "Enable StartTLS"
        },
        verifyOnUpdate: {
          label: "Verify on update",
          formLabel: "",
          inputPlaceholder: "Send verification email after update"
        }
      }
    },
    tenants: {
      label: "tenant",
      label_other: "tenants",
      createLabel: "Create a new $t(tenants.label)",
      updateLabel: "Update $t(tenants.label)",
      name: {
        label: "Name",
        tableHeader: "$t(tenants.name.label)",
        formLabel: "$t(tenants.name.label)",
        inputPlaceholder: "Enter the name",
        filterInputPlaceholder: "Filter by name"
      },
      displayName: {
        label: "Displayed name",
        tableHeader: "$t(tenants.displayName.label)",
        formLabel: "Name to display",
        inputPlaceholder: "Enter the name to display",
        filterInputPlaceholder: "Filter by displayed name"
      },
      hostName: {
        label: "Hostname",
        tableHeader: "$t(tenants.hostName.label)",
        formLabel: "$t(tenants.hostName.label)",
        inputPlaceholder: "Enter the hostname",
        filterInputPlaceholder: "Filter by hostname"
      },
      enabled: {
        label: "Enabled",
        tableHeader: "Status",
        formLabel: "$t(tenants.enabled.label)",
        inputPlaceholder: "Tenant is enabled",
        filterInputPlaceholder: "Filter by status",
        true: "Enabled",
        false: "Disabled"
      },
      issuer: {
        label: "Issuer",
        tableHeader: "$t(tenants.issuer.label)",
        formLabel: "$t(tenants.issuer.label)",
        inputPlaceholder: "This field will be automatically set on save",
        filterInputPlaceholder: ""
      },
      jwksUri: {
        label: "Jwks Uri",
        tableHeader: "$t(tenants.jwksUri.label)",
        formLabel: "$t(tenants.jwksUri.label)",
        inputPlaceholder: "This field will be automatically set on save",
        filterInputPlaceholder: ""
      },
      "updatedBy.username": {
        label: "Updated By",
        tableHeader: "$t(tenants.updatedBy.username.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by user"
      },
      updatedAt: {
        label: "Updated At",
        tableHeader: "$t(tenants.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by date"
      },
      "createdBy.username": {
        label: "Created By",
        tableHeader: "$t(tenants.createdBy.username.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by creator"
      },
      createdAt: {
        label: "Created At",
        tableHeader: "$t(tenants.createdAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by creation date"
      }
    },
    scheduled: {
      label: "jobs",
      label_other: "jobs",
      start: "Start task",
      pause: "Pause task",
      resume: "Resume task",
      jobName: {
        label: "Name",
        tableHeader: "$t(scheduled.jobName.label)",
        formLabel: "$t(scheduled.jobName.label)",
        inputPlaceholder: "Insert job's name",
        filterInputPlaceholder: "Filter by job's name"
      },
      jobFailureCause: {
        label: "Error",
        tableHeader: "$t(scheduled.jobFailureCause.label)",
        formLabel: "$t(scheduled.jobFailureCause.label)"
      },
      id: {
        label: "Id",
        tableHeader: "$t(scheduled.id.label)",
        formLabel: "$t(scheduled.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by job's id"
      },
      jobGroup: {
        label: "Group",
        tableHeader: "$t(scheduled.jobGroup.label)",
        formLabel: "$t(scheduled.jobGroup.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by job's group"
      },
      jobDescription: {
        label: "Description",
        tableHeader: "$t(scheduled.jobDescription.label)",
        formLabel: "$t(scheduled.jobDescription.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by description"
      },
      triggerGroup: {
        label: "Trigger",
        tableHeader: "$t(scheduled.triggerGroup.label)",
        formLabel: "$t(scheduled.triggerName.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by trigger"
      },
      triggerType: {
        label: "Type",
        tableHeader: "$t(scheduled.triggerType.label)",
        formLabel: "$t(scheduled.triggerType.label)",
        inputPlaceholder: "Select trigger's type",
        filterInputPlaceholder: "Filter by trigger's type"
      },
      jobState: {
        label: "State",
        tableHeader: "$t(scheduled.jobState.label)",
        formLabel: "$t(scheduled.jobState.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by trigger state"
      },
      prevFireTime: {
        label: "Last At",
        tableHeader: "$t(scheduled.prevFireTime.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by previous execution date"
      },
      nextFireTime: {
        label: "Next Schedule",
        tableHeader: "$t(scheduled.nextFireTime.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by next execution date"
      },
      startedAt: {
        label: "Started At",
        tableHeader: "$t(scheduled.startedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by started at"
      },
      completedAt: {
        label: "Completed At",
        tableHeader: "$t(scheduled.completedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by completed at"
      }
    },
    devices: {
      label: "IoT device",
      label_other: "IoT devices",
      labelAlt: "My IoT devices",
      labelView: "Data of {{device}}",
      createLabel: "Add t(devices.label)",
      updateLabel: "Update $t(devices.label)",
      showButtonLabel: "Show {{device}}",
      noDataLabel: "No data found for this device with the current selected filters",
      fieldTimeseriesLabel: "Data",
      lastTimeseriesBtnLabel: "Last measured data",
      toggleInfo: {
        true: "Hode info",
        false: "Show info"
      },
      sendNotifications: {
        true: "Enabled",
        false: "Disabled",
        tableHeader: "Notifications",
        formLabel: "State notifications",
        inputPlaceholder: "I want to be notified about the status of the device",
        filterInputPlaceholder: "Filter by notification state"
      },
      filters: {
        timeseries: {
          label: "Select visible",
          placeholder: "Variables"
        },
        dateTimeRange: {
          label: "Period",
          options: {
            last1Hours: "1 hour",
            last3Hours: "3 hours",
            last6Hours: "6 hours",
            last12Hours: "12 hours",
            last24Hours: "24 hours",
            last48Hours: "48 hours",
            last72Hours: "72 hours",
            lastWeek: "1 week",
            lastTwoWeeks: "2 weeks",
            lastMonth: "1 month",
            last3Months: "3 months",
            last6Months: "6 months",
            custom: "Custom"
          }
        },
        interval: {
          placeholder: "Select range",
          label: "Grouping",
          options: {
            undefined: "None",
            "5m": "5 minutes",
            "15m": "15 minutes",
            "30m": "30 minutes",
            "45m": "45 minutes",
            "1h": "1 hour",
            "1d": "1 day",
            "7d": "1 week",
            "15d": "2 weeks",
            "1mo": "1 month",
            "1y": "1 year"
          }
        }
      },
      inTest: {
        header: "Test mode is active",
        body: "When the device is in test mode, battery and bandwidth consumption are higher than those deriving from normal use"
      },
      "firmware.version": {
        label: "FW",
        exportLabel: "$t(devices.firmware.version.label)",
        tableHeader: "$t(devices.firmware.version.label)",
        formLabel: "$t(devices.firmware.version.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by firmware version"
      },
      "radioFirmware.version": {
        label: "Radio FW",
        exportLabel: "$t(devices.radioFirmware.version.label)",
        tableHeader: "$t(devices.radioFirmware.version.label)",
        formLabel: "$t(devices.radioFirmware.version.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by radio firmware version"
      },
      lastDataReceivedAt: {
        label: "Last transmission date/time",
        tableHeader: "$t(devices.lastDataReceivedAt.label)"
      },
      hardwareVersion: {
        label: "HW version",
        exportLabel: "$t(devices.hardwareVersion.label)",
        tableHeader: "$t(devices.hardwareVersion.label)",
        formLabel: "$t(devices.hardwareVersion.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by hardware version"
      },
      imei: {
        label: "Imei",
        exportLabel: "Imei",
        tableHeader: "$t(devices.imei.label)",
        formLabel: "$t(devices.imei.label)",
        inputPlaceholder: "Enter the imei",
        filterInputPlaceholder: "Filter by imei"
      },
      macAddress: {
        label: "MAC address",
        exportLabel: "MAC address",
        tableHeader: "$t(devices.macAddress.label)",
        formLabel: "$t(devices.macAddress.label)",
        inputPlaceholder: "Enter the MAC address",
        filterInputPlaceholder: "Filter by MAC address"
      },
      solarVoltage: {
        tableHeader: "Charging voltage"
      },
      batteryChargeStatus: {
        tableHeader: "Battery status",
        undefined: "N/D",
        ok: "full",
        charging: "charging",
        error: "error"
      },
      batteryLevel: {
        label: "Battery level",
        exportLabel: "$t(devices.batteryLevel.label)",
        tableHeader: "$t(devices.batteryLevel.label)",
        formLabel: "$t(devices.batteryLevel.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by battery level"
      },
      signalPower: {
        label: "Signal power",
        exportLabel: "$t(devices.signalPower.label)",
        tableHeader: "$t(devices.signalPower.label)",
        formLabel: "$t(devices.signalPower.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by signal power"
      },
      simICCID: {
        label: "ICCID",
        exportLabel: "$t(devices.simICCID.label)",
        tableHeader: "$t(devices.simICCID.label)",
        formLabel: "$t(devices.simICCID.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by ICCID"
      },
      networkInfo: {
        label: "Network info",
        exportLabel: "$t(devices.networkInfo.label)",
        tableHeader: "$t(devices.networkInfo.label)",
        formLabel: "$t(devices.networkInfo.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by network info"
      },
      configuration: {
        fieldSetLabel: "Station configuration",
        transmissionRate: {
          label: "Transmitt every",
          exportLabel: "$t(devices.configuration.transmissionRate.label)",
          tableHeader: "$t(devices.configuration.transmissionRate.label)",
          formLabel: "$t(devices.configuration.transmissionRate.label)",
          inputPlaceholder: "Enter the transmission rate",
          filterInputPlaceholder: ""
        },
        band: {
          label: "Band",
          exportLabel: "$t(devices.configuration.band.label)",
          tableHeader: "$t(devices.configuration.band.label)",
          formLabel: "$t(devices.configuration.band.label)",
          inputPlaceholder: "Enter the band",
          filterInputPlaceholder: ""
        },
        simOperator: {
          label: "Carrier",
          name: {
            tableHeader: "$t(devices.configuration.simOperator.label)",
            filterInputPlaceholder: "Filter by sim operator"
          },
          exportLabel: "$t(devices.configuration.simOperator.label)",
          formLabel: "$t(devices.configuration.simOperator.label)",
          inputPlaceholder: "Enter the SIM operator"
        }
      },
      lastConfigUpdatedAt: {
        label: "Config updated at",
        tableHeader: "$t(devices.lastConfigUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by last config update date"
      },
      lastConfigTransmittedAt: {
        label: "Config transmission",
        tableHeader: "$t(devices.lastConfigTransmittedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by last config transmission date"
      },
      lastFirmwareUpdatedAt: {
        label: "FW updated at",
        tableHeader: "$t(devices.lastFirmwareUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by last firmware update date"
      },
      lastRadioFirmwareUpdatedAt: {
        label: "Radio FW updated at",
        tableHeader: "$t(devices.lastRadioFirmwareUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by last radio firmware update date"
      },
      lastCommandTransmittedAt: {
        label: "Commands transmission",
        tableHeader: "$t(devices.lastCommandTransmittedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by last commend transmission date"
      },
      name: {
        label: "Name",
        exportLabel: "Device's name",
        tableHeader: "$t(devices.name.label)",
        formLabel: "$t(devices.name.label)",
        inputPlaceholder: "Enter the name",
        filterInputPlaceholder: "Filter by name"
      },
      iotDeviceDefinition: {
        exportLabel: "Device's type",
        "name.en": {
          label: "Type",
          tableHeader: "$t(devices.iotDeviceDefinition.name.en.label)",
          filterInputPlaceholder: "Filter by type"
        },
        formLabel: "$t(devices.iotDeviceDefinition.name.en.label)",
        inputPlaceholder: "Device's type"
      },
      tenant: {
        name: {
          label: "Customer",
          tableHeader: "$t(devices.tenant.name.label)",
          filterInputPlaceholder: "Filter by customer"
        },
        formLabel: "$t(devices.tenant.name.label)",
        inputPlaceholder: "Enter the customer"
      },
      sensors: {
        fieldSetLabel: "Connect and configure sensors",
        inputPlaceholder: "Select a sensor to add",
        denominationPlaceholder: "Add sensor denomination",
        dynamicListError: "Select a sensor or click on the 'Remove sensor' button",
        formLabel: "Configure device sensors",
        sensor: "Sensor",
        acqRate: "Acquire every",
        sampleRate: "Sample every",
        sdi12Label: "Sensors with SDI-12 interface",
        rs485Label: "Sensors with RS-485 interface",
        digitalLabel: "Digital sensors",
        ptLabel: "PT100/PT1000 sensors",
        localLabel: "Analog sensors",
        remoteLabel: "Remote sensors",
        remove: "",
        removeBtn: "Remove sensor",
        variables: "Variables",
        definitionCode: {
          label: "Sensors",
          tableHeader: "$t(devices.sensors.definitionCode.label)",
          formLabel: "$t(devices.sensors.definitionCode.label)",
          inputPlaceholder: "Add device's sensors",
          filterInputPlaceholder: "Filter by sensors"
        }
      },
      address: {
        label: "Address",
        fieldSetLabel: "Geolocation",
        formatted: {
          label: "$t(devices.address.label)",
          tableHeader: "$t(devices.address.label)",
          inputPlaceholder: "Enter {{coordinate}}",
          filterInputPlaceholder: "Filter by address"
        },
        location: {
          coordinates: {
            label: "Coordinates (Lng, Lat, Alt)",
            tableHeader: "$t(devices.address.location.coordinates.label)"
          }
        },
        formLabel: "Device's position",
        switchLabel: "Update the address when the marker position is updated"
      },
      "lastKnownLocation.coordinates": {
        label: "Position (Lng, Lat, Alt)",
        tableHeader: "$t(devices.lastKnownLocation.coordinates.label)",
        formLabel: "$t(devices.lastKnownLocation.coordinates.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      lastKnownLocationUpdatedAt: {
        label: "Position Update",
        tableHeader: "$t(devices.lastKnownLocationUpdatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by last known position updated at"
      },
      updatedBy: {
        label: "Updated By",
        tableHeader: "$t(devices.updatedBy.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by user"
      },
      updatedAt: {
        label: "Updated At",
        tableHeader: "$t(devices.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      },
      cultureType: {
        label: "Soil type",
        tableHeader: "$t(devices.cultureType.label)",
        formLabel: "$t(devices.cultureType.label) (for the soil water content)",
        inputPlaceholder: "Select soil type"
      },
      v12: {
        tableHeader: "12V"
      }
    },
    "wireless-devices": {
      label: "IoT wireless device",
      label_other: "IoT wireless devices",
      labelAlt: "My IoT wireless devices",
      showButtonLabel: "Show {{device}}",
      createLabel: "Add $t(wireless-devices.label)",
      updateLabel: "Update $t(wireless-devices.label)",
      firmware: {
        label: "FW",
        exportLabel: "$t(wireless-devices.firmware.label)",
        tableHeader: "$t(wireless-devices.firmware.label)",
        formLabel: "$t(wireless-devices.firmware.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by firmware version"
      },
      macAddress: {
        label: "MAC address",
        exportLabel: "MAC address",
        tableHeader: "$t(wireless-devices.macAddress.label)",
        formLabel: "$t(wireless-devices.macAddress.label)",
        inputPlaceholder: "Enter the MAC address",
        filterInputPlaceholder: "Filter by MAC address"
      },
      tenant: {
        name: {
          label: "Tenant",
          tableHeader: "$t(wireless-devices.tenant.name.label)",
          filterInputPlaceholder: "Filter by tenant"
        },
        formLabel: "$t(devices.tenant.name.label)",
        inputPlaceholder: "Select tenant"
      },
      "iotSensor.denomination": {
        label: "Sensor",
        tableHeader: "$t(wireless-devices.iotSensor.denomination.label)"
      },
      iotDevice: {
        name: {
          label: "Device",
          tableHeader: "$t(wireless-devices.iotDevice.name.label)",
          filterInputPlaceholder: "Filter by device"
        },
        formLabel: "$t(wireless-devices.iotDevice.name.label)",
        inputPlaceholder: "Select device"
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(wireless-devices.updatedBy.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by user"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(wireless-devices.updatedAt.label)",
        formLabel: "",
        inputPlaceholder: "",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    sensors: {
      label: "IoT sensor",
      label_other: "IoT sensors",
      labelAlt: "My IoT sensors",
      createLabel: "Add $t(sensors.label)",
      updateLabel: "Update $t(sensors.label)",
      showButtonLabel: "Show {{sensors}}",
      connect: "Connect sensor",
      disconnect: "Disconnect sensor",
      id: {
        exportLabel: "Sensor id"
      },
      denomination: {
        label: "Name",
        exportLabel: "$t(sensors.denomination.label)",
        tableHeader: "$t(sensors.denomination.label)",
        inputPlaceholder: "Add sensor name",
        filterInputPlaceholder: "Filter by sensor name"
      },
      sensorType: {
        label: "Connection",
        tableHeader: "$t(sensors.sensorType.label)"
      },
      tenant: {
        label: "Customer",
        tableHeader: "$t(sensors.tenant.label)",
        filterInputPlaceholder: "Filter by customer"
      },
      wirelessDevice: {
        formLabel: "Wireless device",
        inputPlaceholder: "Select wireless device",
        macAddress: {
          label: "Device MAC Address",
          exportLabel: "$t(sensors.wirelessDevice.macAddress.label)",
          tableHeader: "$t(sensors.wirelessDevice.macAddress.label)"
        }
      },
      port: {
        label: "Port",
        tableHeader: "$t(sensors.port.label)",
        filterInputPlaceholder: "Filter by port"
      },
      deviceName: {
        label: "Device",
        tableHeader: "$t(sensors.deviceName.label)",
        filterInputPlaceholder: "Filter by device"
      },
      enabled: {
        label: "State",
        tableHeader: "$t(sensors.enabled.label)",
        filterInputPlaceholder: "Filter by state"
      },
      "variables.name.en": {
        label: "Variables",
        tableHeader: "$t(sensors.variables.name.it.label)"
      },
      definitionCode: {
        label: "Code",
        exportLabel: "Sensor code",
        tableHeader: "$t(sensors.definitionCode.label)",
        filterInputPlaceholder: "Filter by sensor code"
      },
      sampleRate: {
        label: "$t(sampleRate)",
        tableHeader: "$t(sampleRate)"
      },
      acquisitionRate: {
        label: "$t(acquisitionRate)",
        tableHeader: "$t(acquisitionRate)"
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(groups.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(groups.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      }
    },
    definitions: {
      variables: {
        label: "IoT variable",
        label_other: "IoT variables",
        createLabel: "Add $t(definitions.variables.label)",
        updateLabel: "Update $t(definitions.variables.label)",
        id: {
          label: "Id",
          exportLabel: "Variable's id",
          tableHeader: "$t(definitions.variables.id.label)",
          formLabel: "$t(definitions.variables.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        name: {
          fieldSetLabel: "Name",
          exportLabel: "Variable's name",
          it: {
            label: "Italian Name",
            tableHeader: "$t(definitions.variables.name.it.label)",
            formLabel: "Italian",
            inputPlaceholder: "Enter the italian name",
            filterInputPlaceholder: "Filter by name (italian)"
          },
          en: {
            label: "English Name",
            tableHeader: "$t(definitions.variables.name.en.label)",
            formLabel: "English",
            inputPlaceholder: "Enter the english name",
            filterInputPlaceholder: "Filter by name (english)"
          }
        },
        measureUnit: {
          label: "Measure Unit",
          exportLabel: "$t(definitions.variables.measureUnit.label)",
          tableHeader: "$t(definitions.variables.measureUnit.label)",
          formLabel: "Enter measure unit",
          inputPlaceholder: "Measure unit",
          filterInputPlaceholder: "Filter by measure unit"
        },
        updatedBy: {
          label: "Updated By",
          tableHeader: "$t(definitions.variables.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated At",
          tableHeader: "$t(definitions.variables.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by update date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      sensors: {
        label: "IoT sensor",
        label_other: "IoT sensors",
        createLabel: "Add $t(definitions.sensors.label)",
        updateLabel: "Update $t(definitions.sensors.label)",
        "wiredDefaults.fieldSetLabel": "Default local configuration",
        "wirelessDefaults.fieldSetLabel": "Default remote configuration",
        id: {
          label: "Id",
          tableHeader: "$t(definitions.sensors.id.label)",
          formLabel: "$t(definitions.sensors.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        sensorDefinitionCode: {
          label: "Sensor's code",
          tableHeader: "$t(definitions.sensors.sensorDefinitionCode.label)",
          formLabel: "$t(definitions.sensors.sensorDefinitionCode.label)",
          inputPlaceholder: "Add sensor's code definition",
          filterInputPlaceholder: "Filter bby sensor's code definition"
        },
        brand: {
          label: "Brand",
          tableHeader: "$t(definitions.sensors.brand.label)",
          formLabel: "$t(definitions.sensors.brand.label)",
          inputPlaceholder: "Add sensor brand",
          filterInputPlaceholder: "Filter by brand"
        },
        model: {
          label: "Model",
          tableHeader: "$t(definitions.sensors.model.label)",
          formLabel: "$t(definitions.sensors.model.label)",
          inputPlaceholder: "Add sensor mode",
          filterInputPlaceholder: "Filter per model"
        },
        transducer: {
          label: "Transducer",
          tableHeader: "$t(definitions.sensors.transducer.label)",
          formLabel: "$t(definitions.sensors.transducer.label)",
          inputPlaceholder: "Add sensor transducer",
          filterInputPlaceholder: "Filter by transducer"
        },
        dataSheetUrl: {
          label: "Datasheet Url",
          tableHeader: "$t(definitions.sensors.dataSheetUrl.label)",
          formLabel: "$t(definitions.sensors.dataSheetUrl.label)",
          inputPlaceholder: "Add sensor datasheet url",
          filterInputPlaceholder: "Filter by datasheet url"
        },
        updatedBy: {
          label: "Updated By",
          tableHeader: "$t(definitions.sensors.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated At",
          tableHeader: "$t(definitions.sensors.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by update date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        },
        measuredVariables: {
          "name.en": {
            label: "Variables",
            tableHeader: "$t(definitions.sensors.measuredVariables.name.en.label)",
            filterInputPlaceholder: "Filter by variables"
          },
          inputPlaceholder: "Add measured variables",
          formLabel: "Measured variables"
        },
        communicationInterfaces: {
          label: "Interfaces",
          formLabel: "Communication interfaces",
          tableHeader: "$t(definitions.sensors.communicationInterfaces.label)",
          inputPlaceholder: "Add communication interfaces",
          filterInputPlaceholder: "Filter by communication interfaces"
        },
        defaultPort: {
          label: "Default port",
          tableHeader: "$t(definitions.sensors.defaultPort.label)",
          formLabel: "$t(definitions.sensors.defaultPort.label)",
          inputPlaceholder: "1",
          filterInputPlaceholder: ""
        },
        defaultBusAddress: {
          label: "Default bus address",
          tableHeader: "$t(definitions.sensors.defaultBusAddress.label)",
          formLabel: "$t(definitions.sensors.defaultBusAddress.label)",
          inputPlaceholder: "1",
          filterInputPlaceholder: ""
        },
        defaultConfig: {
          fieldSetLabel: "",
          "sampleRate.label": "sample rate",
          "acquisitionRate.label": "acquisition rate",
          wiredDefaults: {
            sampleRate: {
              label: "Local $t(definitions.sensors.defaultConfig.sampleRate.label)",
              tableHeader: "$t(definitions.sensors.defaultConfig.wiredDefaults.sampleRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wiredDefaults.sampleRate.tableHeader)",
              inputPlaceholder: "Add local sample rate"
            },
            acquisitionRate: {
              label: "Local $t(definitions.sensors.defaultConfig.acquisitionRate.label)",
              tableHeader: "$t(definitions.sensors.defaultConfig.wiredDefaults.acquisitionRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wiredDefaults.acquisitionRate.tableHeader)",
              inputPlaceholder: "Add local acquisition rate"
            }
          },
          wirelessDefaults: {
            sampleRate: {
              label: "Remote $t(definitions.sensors.defaultConfig.sampleRate.label)",
              tableHeader: "$t(definitions.sensors.defaultConfig.wirelessDefaults.sampleRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wirelessDefaults.sampleRate.tableHeader)",
              inputPlaceholder: "Add remote sample rate"
            },
            acquisitionRate: {
              label: "Remote $t(definitions.sensors.defaultConfig.acquisitionRate.label)",
              tableHeader: "$t(definitions.sensors.defaultConfig.wirelessDefaults.acquisitionRate.label)",
              formLabel: "$t(definitions.sensors.defaultConfig.wirelessDefaults.acquisitionRate.tableHeader)",
              inputPlaceholder: "Add remote acquisition rate"
            }
          }
        }
      },
      devices: {
        label: "IoT device definition",
        label_other: "IoT device definitions",
        createLabel: "Add $t(definitions.devices.label)",
        updateLabel: "Update $t(definitions.devices.label)",
        deviceDefinitionCode: {
          label: "Device's code",
          tableHeader: "$t(definitions.devices.deviceDefinitionCode.label)",
          formLabel: "$t(definitions.devices.deviceDefinitionCode.label)",
          inputPlaceholder: "Add device's code",
          filterInputPlaceholder: "Filter by device's code"
        },
        id: {
          label: "Id",
          tableHeader: "$t(definitions.devices.id.label)",
          formLabel: "$t(definitions.devices.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        name: {
          fieldSetLabel: "Name",
          it: {
            label: "Italian Name",
            tableHeader: "$t(definitions.devices.name.it.label)",
            formLabel: "Italian",
            inputPlaceholder: "Enter the italian name",
            filterInputPlaceholder: "Filter by name (italian)"
          },
          en: {
            label: "English Name",
            tableHeader: "$t(definitions.devices.name.en.label)",
            formLabel: "English",
            inputPlaceholder: "Enter the english name",
            filterInputPlaceholder: "Filter by name (english)"
          }
        },
        defaultConfig: {
          wired: "Wired",
          wireless: "Wireless",
          fieldSetLabel: "Default configuration",
          transmissionRate: {
            label: "Transmitt every",
            exportLabel: "$t(definitions.devices.defaultConfig.transmissionRate.label)",
            tableHeader: "$t(definitions.devices.defaultConfig.transmissionRate.label)",
            formLabel: "$t(definitions.devices.defaultConfig.transmissionRate.label)",
            inputPlaceholder: "Enter transmission time ",
            filterInputPlaceholder: ""
          },
          sensors: {
            formLabel: "Default sensors configuration",
            resetEditorInput: "Cancel",
            saveEditorInput: {
              add: "Add",
              update: "Update"
            }
          }
        },
        defaultTransmissionRate: {
          label: "Transmission rate",
          tableHeader: "$t(definitions.devices.defaultTransmissionRate.label)",
          formLabel: "$t(definitions.devices.defaultTransmissionRate.label)",
          inputPlaceholder: "Add transmission rate",
          filterInputPlaceholder: "Filter by transmission rate"
        },
        "sensors.fieldSetLabel": "",
        iotDevices: {
          name: {
            label: "Devices",
            tableHeader: "$t(definitions.devices.iotDevices.name.label)",
            filterInputPlaceholder: "Filter by device"
          },
          formLabel: "Devices",
          inputPlaceholder: "Add devices"
        },
        updatedBy: {
          label: "Updated By",
          tableHeader: "$t(definitions.devices.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated At",
          tableHeader: "$t(definitions.devices.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by update date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      cultures: {
        label: "agea's culture",
        label_other: "agea's cultures",
        createLabel: "Add culture",
        updateLabel: "Update culture",
        id: {
          label: "Id",
          tableHeader: "$t(definitions.cultures.id.label)",
          formLabel: "$t(definitions.cultures.id.label) Agea",
          inputPlaceholder: "Add the agea's culture id",
          filterInputPlaceholder: "Filter by agea's culture id"
        },
        useCode: {
          label: "Use destination",
          tableHeader: "$t(definitions.cultures.useCode.label)",
          formLabel: "$t(definitions.cultures.useCode.label)",
          inputPlaceholder: "Add the agea's destination use code",
          filterInputPlaceholder: "Filter by agea's destination use code"
        },
        name: {
          fieldSetLabel: "Name",
          it: {
            label: "Italian name",
            tableHeader: "$t(definitions.cultures.name.it.label)",
            formLabel: "Italian",
            inputPlaceholder: "Add the italian name",
            filterInputPlaceholder: "Filter by name (italian)"
          },
          en: {
            label: "English name",
            tableHeader: "$t(definitions.cultures.name.en.label)",
            formLabel: "English",
            inputPlaceholder: "Add the english name",
            filterInputPlaceholder: "Filter by name (inglese)"
          }
        },
        cropCoefficients: {
          fieldSetLabel: "Coefficienti culturali di riferimento",
          initialCropCoefficient: {
            label: "Crop coeff. initial stage (Kc_ini)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.initialCropCoefficient.label)",
            formLabel: "Initial stage crop coefficient (Kc_ini)",
            inputPlaceholder: "Enter initial stage crop coefficient",
            filterInputPlaceholder: "Filter by initial stage crop coeff."
          },
          midCropCoefficient: {
            label: "Crop coeff. mid stage (Kc_mid)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.midCropCoefficient.label)",
            formLabel: "Mid stage crop coefficient (Kc_mid)",
            inputPlaceholder: "Enter mid stage crop coefficient",
            filterInputPlaceholder: "Filter by mid stage crop coeff."
          },
          endCropCoefficient: {
            label: "Crop coeff. end stage (Kc_end)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.endCropCoefficient.label)",
            formLabel: "End stage crop coefficient (Kc_end)",
            inputPlaceholder: "Enter end stage crop coefficient",
            filterInputPlaceholder: "Filter by end stage crop coeff."
          }
        },
        growthStagesLengths: {
          fieldSetLabel: "Length of growth stages",
          initialSeasonLen: {
            label: "Initial stage length (L_ini)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            inputPlaceholder: "Enter initial stage length",
            filterInputPlaceholder: "Filter by initial stage length"
          },
          developmentSeasonLen: {
            label: "Dev stage length (L_dev)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            inputPlaceholder: "Enter dev stage length",
            filterInputPlaceholder: "Filter by dev stage length"
          },
          midSeasonLen: {
            label: "Mid stage length (L_mid)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            inputPlaceholder: "Enter mid stage length",
            filterInputPlaceholder: "Filter by mid stage length"
          },
          endSeasonLen: {
            label: "End stage length (L_end)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            inputPlaceholder: "Enter end stage length",
            filterInputPlaceholder: "Filter by end stage length"
          }
        },
        updatedBy: {
          label: "Updated By",
          tableHeader: "$t(definitions.cultures.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated At",
          tableHeader: "$t(definitions.cultures.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by update date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      varieties: {
        label: "agea's culture variety",
        label_other: "agea's culture varieties",
        createLabel: "Add variety",
        updateLabel: "Update variety",
        id: {
          label: "Id",
          tableHeader: "$t(definitions.cultures.id.label)",
          formLabel: "$t(definitions.cultures.id.label)",
          inputPlaceholder: "",
          filterInputPlaceholder: ""
        },
        varietyId: {
          label: "Variety's code",
          tableHeader: "$t(definitions.varieties.varietyId.label)",
          formLabel: "$t(definitions.varieties.varietyId.label)",
          inputPlaceholder: "Enter the Agea's variety code",
          filterInputPlaceholder: "Filter by Agea's variety code"
        },
        name: {
          fieldSetLabel: "Name",
          it: {
            label: "Italian name",
            tableHeader: "$t(definitions.cultures.name.it.label)",
            formLabel: "Italian",
            inputPlaceholder: "Enter the italian name",
            filterInputPlaceholder: "Filtra per nome (italiano)"
          },
          en: {
            label: "English name",
            tableHeader: "$t(definitions.cultures.name.en.label)",
            formLabel: "English",
            inputPlaceholder: "Enter the english name",
            filterInputPlaceholder: "Filter by name (english)"
          }
        },
        culture: {
          id: {
            tableHeader: "Culture's code",
            filterInputPlaceholder: "Filter by Agea's culture code"
          },
          "name.en": {
            label: "Colture",
            tableHeader: "$t(definitions.varieties.culture.name.en.label)",
            filterInputPlaceholder: "Filter by Agea's culture name"
          },
          inputPlaceholder: "Select Agea's culture",
          formLabel: "$t(definitions.varieties.culture.name.en.label)"
        },
        cropCoefficients: {
          fieldSetLabel: "Coefficienti culturali di riferimento",
          initialCropCoefficient: {
            label: "Crop coeff. initial stage (Kc_ini)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.initialCropCoefficient.label)",
            formLabel: "Initial stage crop coefficient (Kc_ini)",
            inputPlaceholder: "Enter initial stage crop coefficient",
            filterInputPlaceholder: "Filter by initial stage crop coeff."
          },
          midCropCoefficient: {
            label: "Crop coeff. mid stage (Kc_mid)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.midCropCoefficient.label)",
            formLabel: "Mid stage crop coefficient (Kc_mid)",
            inputPlaceholder: "Enter mid stage crop coefficient",
            filterInputPlaceholder: "Filter by mid stage crop coeff."
          },
          endCropCoefficient: {
            label: "Crop coeff. end stage (Kc_end)",
            tableHeader: "$t(definitions.cultures.cropCoefficients.endCropCoefficient.label)",
            formLabel: "End stage crop coefficient (Kc_end)",
            inputPlaceholder: "Enter end stage crop coefficient",
            filterInputPlaceholder: "Filter by end stage crop coeff."
          }
        },
        growthStagesLengths: {
          fieldSetLabel: "Length of growth stages",
          initialSeasonLen: {
            label: "Initial stage length (L_ini)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.initialSeasonLen.label)",
            inputPlaceholder: "Enter initial stage length",
            filterInputPlaceholder: "Filter by initial stage length"
          },
          developmentSeasonLen: {
            label: "Dev stage length (L_dev)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.developmentSeasonLen.label)",
            inputPlaceholder: "Enter dev stage length",
            filterInputPlaceholder: "Filter by dev stage length"
          },
          midSeasonLen: {
            label: "Mid stage length (L_mid)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.midSeasonLen.label)",
            inputPlaceholder: "Enter mid stage length",
            filterInputPlaceholder: "Filter by mid stage length"
          },
          endSeasonLen: {
            label: "End stage length (L_end)",
            tableHeader: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            formLabel: "$t(definitions.cultures.growthStagesLengths.endSeasonLen.label)",
            inputPlaceholder: "Enter end stage length",
            filterInputPlaceholder: "Filter by end stage length"
          }
        },
        updatedBy: {
          label: "Updated By",
          tableHeader: "$t(definitions.varieties.updatedBy.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated At",
          tableHeader: "$t(definitions.varieties.updatedAt.label)",
          formLabel: "",
          inputPlaceholder: "",
          filterInputPlaceholder: "Filter by update date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      }
    },
    fields: {
      allLabel: "All fields",
      addButton: "Add field",
      label: "field",
      label_other: "fields",
      createLabel: "Add $t(fields.label)",
      updateLabel: "Update $t(fields.label)",
      updateButtonLabel: "Update field",
      updateButtonLabelOther: "Update information",
      updateDropdownItem: "$t(fields.updateButtonLabel)",
      deleteDropdownItem: "Delete field",
      backButtonLabel: "Back to field",
      backButtonLabel_other: "Back to fields",
      generalInformation: "General informations",
      goBackToField: "Go back to field",
      editInfoLabel: "Update additional info",
      searchBarPlaceholder: "Search by field name or group...",
      table: {
        imei: "Imei",
        device: "Device",
        battery: "Battery"
      },
      area: {
        label: "Total surface",
        tableHeader: "$t(fields.area.label) (m²)",
        formLabel: "$t(fields.area.label)",
        inputPlaceholder: "Add the field surface"
      },
      name: {
        label: "Field name",
        formLabel: "$t(fields.name.label)",
        inputPlaceholder: "Add the field name"
      },
      group: {
        none: "None",
        label: "Field's group",
        formLabel: "Field's group",
        inputPlaceholder: "Select or create the field group"
      },
      iotDevices: {
        label: "Devices",
        formLabel: "$t(fields.iotDevices.label)",
        inputPlaceholder: "Add devices to field",
        selectPlacelholder: "Select a device..."
      },
      tenant: {
        label: "Customer",
        formLabel: "$t(fields.tenant.label)",
        inputPlaceholder: "Select field's customer"
      },
      soilGranulometry: {
        label: "Granulometry",
        summaryLabel: "$t(fields.soilGranulometry.label)",
        formLabel: "$t(fields.soilGranulometry.label)",
        inputPlaceholder: "Select soil granulometry"
      },
      coverageType: {
        label: "Coverage type",
        summaryLabel: "$t(fields.coverageType.label)",
        formLabel: "$t(fields.coverageType.label)",
        inputPlaceholder: "Select the coverage's type"
      },
      productionBatchCode: {
        label: "Production batch code",
        summaryLabel: "$t(fields.productionBatchCode.label)",
        formLabel: "$t(fields.productionBatchCode.label)",
        inputPlaceholder: "Add production batch code",
        filterInputPlaceholder: "Filter by production batch code"
      },
      irrigation: {
        fieldSetLabel: "Irrigation",
        method: {
          label: "Irrigation's method",
          summaryLabel: "$t(fields.irrigation.method.label)",
          formLabel: "$t(fields.irrigation.method.label)",
          inputPlaceholder: "Select the irrigation's method"
        },
        source: {
          label: "Irrigation's source",
          summaryLabel: "$t(fields.irrigation.source.label)",
          formLabel: "$t(fields.irrigation.source.label)",
          inputPlaceholder: "Select the irrigation's source"
        }
      },
      type: {
        label: "Field's type",
        summaryLabel: "$t(fields.type.label)",
        formLabel: "$t(fields.type.label)",
        inputPlaceholder: "Select the field's type"
      },
      address: {
        label: "Address",
        formLabel: "$t(fields.address.label)",
        inputPlaceholder: "Enter the address"
      },
      cadastralParticles: {
        label: "Cadastral data",
        addBtn: "Download from cadastre",
        getBtn: "$t(fields.cadastralParticles.addBtn)",
        updateBtn: "Update cadastral data",
        editBtn: "$t(fields.cadastralParticles.updateBtn)",
        saveBtn: "Save cadastral data",
        fetchError: "Error while fetching cadastral data from agenzia delle entrate, try again later",
        formLabel: "Add data manually",
        addParticleBtn: "Add particle",
        place: "City",
        locality: { label: "City", inputPlaceholder: "FIRENZE", valid: "must have a valid value" },
        district: { label: "District", inputPlaceholder: "FI", valid: "must have length of 2" },
        sheet: { label: "Sheet", inputPlaceholder: "1492", valid: "must be a number" },
        particle: { label: "Particle", inputPlaceholder: "14", valid: "must be a number" }
      },
      soilProperties: {
        label: "Soil characteristics",
        formLabel: "",
        fieldSetLabel: "",
        outputFieldSetLabel: "Hydrological constants",
        hydrologicalConstantsCfgBtnLabel: "Configure hydrological constants",
        hydrologicalConstantsBtnLabel: "Calculate",
        configure: "Configure",
        confirm:
          "The operation will overwrite the hydrological constants data and potentially the bulk density. Proceed anyway?",
        soilDataLabel: "Soil",
        waterDataLabel: "Water",
        selectMoistureSensorPlaceholder: "Select moisture sensor",
        vwc: {
          label: "Soil moisture (VWC)",
          updatedAt: "Updated on",
          time: "at",
          ok: "Optimal amount of water",
          reduce: "Reduce the amount of water",
          alert: "Caution, water immediately"
        },
        soilTexture: {
          label: "Texture",
          formLabel: "USDA soil textures",
          inputPlaceholder: "Select texture"
        },
        clay: {
          label: "Clay",
          formLabel: "$t(fields.soilProperties.clay.label)",
          inputPlaceholder: "Add clay percentage"
        },
        sand: {
          label: "Sand",
          formLabel: "$t(fields.soilProperties.sand.label)",
          inputPlaceholder: "Add sand percentage"
        },
        silt: {
          label: "Silt"
        },
        organicMatter: {
          label: "Organic matter",
          formLabel: "$t(fields.soilProperties.organicMatter.label)",
          inputPlaceholder: "Add organic matter"
        },
        electricalConductivity: {
          //label: "Saturation Extract Electrical Conductivity (ECe)",
          label: "Saturation Extracted EC (ECe)",
          formLabel: "$t(fields.soilProperties.electricalConductivity.label)",
          inputPlaceholder: "Set the Saturated Soil-Paste Electrical Conductivity (ECe)"
        },
        gravel: {
          label: "Gravel",
          formLabel: "$t(fields.soilProperties.gravel.label)",
          inputPlaceholder: "Add gravel percentage"
        },
        compaction: {
          label: "Compaction",
          formLabel: "$t(fields.soilProperties.compaction.label)",
          inputPlaceholder: "Add compaction"
        },
        fieldCapacity: {
          label: "Field capacity",
          formLabel: "$t(fields.soilProperties.fieldCapacity.label)",
          inputPlaceholder: "...",
          progressBarLabel: "Optimal state: {{wiltingPoint}} < VWC ≤ {{fieldCapacity}} "
        },
        wiltingPoint: {
          label: "Wilting point",
          formLabel: "$t(fields.soilProperties.wiltingPoint.label)",
          inputPlaceholder: "...",
          progressBarLabel: "Irreversible critical state: VWC ≤ {{wiltingPoint}}"
        },
        saturation: {
          label: "Saturation",
          formLabel: "$t(fields.soilProperties.saturation.label)",
          inputPlaceholder: "...",
          progressBarLabel: "State of suffering: VWC > {{fieldCapacity}}"
        },
        availableWater: {
          label: "Available water",
          formLabel: "$t(fields.soilProperties.availableWater.label)",
          inputPlaceholder: "..."
        },
        saturatedHydraulicConductivity: {
          label: "Saturated hydraulic conductivity",
          formLabel: "$t(fields.soilProperties.saturatedHydraulicConductivity.label)",
          inputPlaceholder: "..."
        },
        bulkDensity: {
          label: "Bulk density",
          formLabel: "$t(fields.soilProperties.bulkDensity.label)",
          inputPlaceholder: "..."
        },
        ph: {
          label: "pH",
          formLabel: "$t(fields.soilProperties.ph.label)",
          inputPlaceholder: "..."
        },
        activeLimestone: {
          label: "Active limestone",
          formLabel: "$t(fields.soilProperties.activeLimestone.label)",
          inputPlaceholder: "..."
        },
        potassiumOxide: {
          label: "Potassium oxide (K₂O)",
          formLabel: "$t(fields.soilProperties.potassiumOxide.label)",
          inputPlaceholder: "..."
        },
        phosphoricAnhydride: {
          label: "Phosphoric Anhydride (P₂O₅)",
          formLabel: "$t(fields.soilProperties.phosphoricAnhydride.label)",
          inputPlaceholder: "..."
        }
      }
    },
    groups: {
      label: "group",
      label_other: "Groups of fields",
      createLabel: "Add $t(groups.label)",
      updateLabel: "Update $t(groups.label)",
      name: {
        label: "Group's name",
        tableHeader: "$t(groups.name.label)",
        formLabel: "$t(groups.name.label)",
        inputPlaceholder: "Add the group's name",
        filterInputPlaceholder: "Filter by group's name"
      },
      fields: {
        name: {
          label: "Fields",
          tableHeader: "$t(groups.fields.name.label)",
          filterInputPlaceholder: "Filter by fields"
        },
        formLabel: "$t(groups.fields.name.label)",
        inputPlaceholder: "Add fields to the group"
      },
      tenant: {
        name: {
          label: "Customer",
          tableHeader: "$t(groups.tenant.name.label)",
          filterInputPlaceholder: "Filter by customer"
        },
        formLabel: "$t(groups.tenant.name.label)",
        inputPlaceholder: "Select customer"
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(groups.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(groups.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    firmwares: {
      update: {
        label: "Update firmware",
        device: "Device",
        radio: "Trasmitter"
      },
      firmwareType: {
        label: "Type",
        tableHeader: "$t(firmwares.firmwareType.label)",
        filterInputPlaceholder: "Filter by type"
      },
      version: {
        label: "Version",
        tableHeader: "$t(firmwares.version.label)",
        filterInputPlaceholder: "Filter by version"
      },
      fileName: {
        label: "File name",
        tableHeader: "$t(firmwares.fileName.label)",
        filterInputPlaceholder: "Filter by name"
      },
      fileUrl: {
        label: "Url",
        tableHeader: "$t(firmwares.fileUrl.label)",
        filterInputPlaceholder: "Filter by url"
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(firmwares.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(firmwares.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "Created by",
        tableHeader: "$t(firmwares.createdBy.label)",
        filterInputPlaceholder: "Filter by creator"
      },
      createdAt: {
        label: "Created at",
        tableHeader: "$t(firmwares.createdAt.label)",
        filterInputPlaceholder: "Filter by creation date"
      }
    },
    "culture-types": {
      label: "culture type",
      label_other: "culture types",
      createLabel: "Add $t(culture-types.label)",
      updateLabel: "Update $t(culture-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(culture-types.id.label)",
        formLabel: "$t(culture-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Name",
        it: {
          label: "Italian Name",
          tableHeader: "$t(culture-types.name.it.label)",
          formLabel: "Italian",
          inputPlaceholder: "Enter the italian name",
          filterInputPlaceholder: "Filter by name (italian)"
        },
        en: {
          label: "English Name",
          tableHeader: "$t(culture-types.name.en.label)",
          formLabel: "English",
          inputPlaceholder: "Enter the english name",
          filterInputPlaceholder: "Filter by name (english)"
        }
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(culture-types.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(culture-types.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "field-types": {
      label: "Management type",
      label_other: "management type",
      createLabel: "Add $t(field-types.label)",
      updateLabel: "Update $t(field-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(field-types.id.label)",
        formLabel: "$t(field-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Name",
        it: {
          label: "Italian Name",
          tableHeader: "$t(field-types.name.it.label)",
          formLabel: "Italian",
          inputPlaceholder: "Enter the italian name",
          filterInputPlaceholder: "Filter by name (italian)"
        },
        en: {
          label: "English Name",
          tableHeader: "$t(field-types.name.en.label)",
          formLabel: "English",
          inputPlaceholder: "Enter the english name",
          filterInputPlaceholder: "Filter by name (english)"
        }
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(field-types.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(field-types.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "coverage-types": {
      label: "Site type",
      label_other: "Site types",
      createLabel: "Add $t(coverage-types.label)",
      updateLabel: "Update $t(coverage-types.label)",
      id: {
        label: "Id",
        tableHeader: "$t(coverage-types.id.label)",
        formLabel: "$t(coverage-types.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Name",
        it: {
          label: "Italian Name",
          tableHeader: "$t(coverage-types.name.it.label)",
          formLabel: "Italian",
          inputPlaceholder: "Enter the italian name",
          filterInputPlaceholder: "Filter by name (italian)"
        },
        en: {
          label: "English Name",
          tableHeader: "$t(coverage-types.name.en.label)",
          formLabel: "English",
          inputPlaceholder: "Enter the english name",
          filterInputPlaceholder: "Filter by name (english)"
        }
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(coverage-types.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(coverage-types.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "irrigation-methods": {
      label: "group",
      label_other: "groups",
      createLabel: "Add $t(irrigation-methods.label)",
      updateLabel: "Update $t(irrigation-methods.label)",
      id: {
        label: "Id",
        tableHeader: "$t(irrigation-methods.id.label)",
        formLabel: "$t(irrigation-methods.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Name",
        it: {
          label: "Italian Name",
          tableHeader: "$t(irrigation-methods.name.it.label)",
          formLabel: "Italian",
          inputPlaceholder: "Enter the italian name",
          filterInputPlaceholder: "Filter by name (italian)"
        },
        en: {
          label: "English Name",
          tableHeader: "$t(irrigation-methods.name.en.label)",
          formLabel: "English",
          inputPlaceholder: "Enter the english name",
          filterInputPlaceholder: "Filter by name (english)"
        }
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(irrigation-methods.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(irrigation-methods.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    "irrigation-sources": {
      label: "irrigation sources",
      label_other: "irrigation sources",
      createLabel: "Add $t(irrigation-sources.label)",
      updateLabel: "Update $t(irrigation-sources.label)",
      id: {
        label: "Id",
        tableHeader: "$t(irrigation-sources.id.label)",
        formLabel: "$t(irrigation-sources.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Name",
        it: {
          label: "Italian Name",
          tableHeader: "$t(irrigation-sources.name.it.label)",
          formLabel: "Italian",
          inputPlaceholder: "Enter the italian name",
          filterInputPlaceholder: "Filter by name (italian)"
        },
        en: {
          label: "English Name",
          tableHeader: "$t(irrigation-sources.name.en.label)",
          formLabel: "English",
          inputPlaceholder: "Enter the english name",
          filterInputPlaceholder: "Filter by name (english)"
        }
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(irrigation-sources.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(irrigation-sources.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    granulometries: {
      label: "soil granulometry",
      label_other: "soil granulometries",
      createLabel: "Add $t(granulometries.label)",
      updateLabel: "Update $t(granulometries.label)",
      id: {
        label: "Id",
        tableHeader: "$t(granulometries.id.label)",
        formLabel: "$t(granulometries.id.label)",
        inputPlaceholder: "",
        filterInputPlaceholder: ""
      },
      name: {
        fieldSetLabel: "Name",
        it: {
          label: "Italian Name",
          tableHeader: "$t(granulometries.name.it.label)",
          formLabel: "Italian",
          inputPlaceholder: "Enter the italian name",
          filterInputPlaceholder: "Filter by name (italian)"
        },
        en: {
          label: "English Name",
          tableHeader: "$t(granulometries.name.en.label)",
          formLabel: "English",
          inputPlaceholder: "Enter the english name",
          filterInputPlaceholder: "Filter by name (english)"
        }
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(granulometries.updatedBy.label)",
        filterInputPlaceholder: "Filter by updater"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(granulometries.updatedAt.label)",
        filterInputPlaceholder: "Filter by update date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    cultures: {
      label: "Culture",
      label_other: "Cultures",
      label_alt: "Plant",
      viewLabelLink: "Show culture",
      createLabel: "Add $t(cultures.label)",
      updateLabel: "Update $t(cultures.label)",
      addButton: "Add",
      historyBtnLabel: "Add to history",
      cycleStartedAt: {
        label: "Sowing/Cycle Start date",
        formLabel: "$t(cultures.cycleStartedAt.label)",
        inputPlaceholder: "Select the sowing/cycle start date"
      },
      cycleEndedAt: {
        label: "End date",
        formLabel: "$t(cultures.cycleEndedAt.label)",
        inputPlaceholder: "Select the end date"
      },
      cultivatedCulture: {
        fieldSetLabel: "Culture's details",
        label: "Category",
        formLabel: "$t(cultures.cultivatedCulture.label)",
        inputPlaceholder: "Select the colture's category"
      },
      cultivatedCultureVariety: {
        label: "variety",
        formLabel: "Culture's $t(cultures.cultivatedCultureVariety.label)",
        inputPlaceholder: "Select the culture's variety"
      },
      area: {
        label: "Cultivated area",
        formLabel: "$t(cultures.area.label) (%)",
        inputPlaceholder: "Enter the cultivated area as a percentage of the total field area"
      },
      plantedUnits: {
        label: "Planted units",
        formLabel: "$t(cultures.plantedUnits.label)",
        inputPlaceholder: "Enter the number of planted units"
      },
      experimental: {
        label: "Experimental",
        formLabel: "Extra",
        inputPlaceholder: "It's an experimental culture",
        true: "Yes",
        false: "No"
      },
      status: {
        label: "Status"
      }
    },
    device: {
      inTest: {
        header: "Test mode is active",
        body: "While in test mode, the device's battery consumption and data consumption are above of the ones in normal mode"
      },
      gpsFix: "Update gps position",
      firmwareUpdates: {
        available: {
          header: "Available firmware updates",
          body: "A new version of the device firmwares are available",
          button: "Update firmwares",
          prompt:
            "By clicking on \"Update firmwares\" the device's firmware updates would be scheduld.\nThe operation could not be dismissed.\nPlease don't turn off the device during the update."
        },
        scheduled: {
          header: "Scheduled firmware updates",
          body: "The device's firmware updates are scheduled and they will run soon.\nIf the device is in test mode the updates will run once the device would come back to normal mode.\nPlease don't turn off the device during the update."
        },
        pending: {
          header: "Running firmware updates",
          body: "The device's firmware updates are running.\nPlease don't turn the device off.\nPlease don't update the device's configuration until the updates are completed."
        }
      },
      info: {
        label: "Device's information"
      },
      notes: {
        btn: {
          false: "Show notes",
          true: "Hide notes",
          save: "Save notes"
        },
        modal: {
          header: "Add notes about the device"
        },
        save: {
          success: "Notes updated correctly",
          error: "Error updating the notes, please try later"
        }
      }
    },
    companies: {
      label: "business profile",
      label_other: "Business profiles",
      createLabel: "Add $t(companies.label)",
      updateLabel: "Update $t(companies.label)",
      updateBtn: "$t(companies.updateLabel)",
      goBack: "Go back",
      goBackToProfile: "Go to profile",
      info: "Information",
      equalAddresses: "Registration Address/Headquarter",
      equalAddressesLabel: "Registration/Headquarter address",
      showUsers: "Add a new user",
      address: {
        streetAddress: {
          inputPlaceholder: "Enter address"
        },
        postalCode: {
          formLabel: "ZIP code",
          inputPlaceholder: "Enter zip code"
        },
        locality: {
          formLabel: "City",
          inputPlaceholder: "Select city"
        },
        region: {
          formLabel: "Province",
          inputPlaceholder: "Select province"
        },
        country: {
          formLabel: "Country",
          inputPlaceholder: "Select country"
        },
        countryCode: {
          formLabel: "Country code"
        }
      },
      companyType: {
        label: "Company type",
        tableHeader: "Type",
        formLabel: "$t(companies.companyType.label)",
        inputPlaceholder: "Select company type",
        filterInputPlaceholder: "Filter by company type"
      },
      firstName: {
        label: "First name",
        tableHeader: "First name",
        formLabel: "$t(companies.firstName.label)",
        inputPlaceholder: "Enter first name",
        filterInputPlaceholder: "Filter by first name"
      },
      lastName: {
        label: "Last name",
        tableHeader: "$t(companies.lastName.label)",
        formLabel: "$t(companies.lastName.label)",
        inputPlaceholder: "Enter last name",
        filterInputPlaceholder: "Filter by last name"
      },
      denomination: {
        label: "Company name",
        tableHeader: "Company name",
        formLabel: "$t(companies.denomination.label)",
        inputPlaceholder: "Enter company name",
        filterInputPlaceholder: "Filter by company name"
      },
      vatNumber: {
        label: "VAT number",
        formLabel: "VAT number",
        tableHeader: "VAT num",
        inputPlaceholder: "Enter VAT number",
        filterInputPlaceholder: "Filter by VAT number"
      },
      fiscalNumber: {
        label: "Fiscal number",
        tableHeader: "Fiscal num",
        formLabel: "$t(companies.fiscalNumber.label)",
        inputPlaceholder: "Enter fiscal number",
        filterInputPlaceholder: "Filter by fiscal number"
      },
      reaNumber: {
        label: "REA number",
        formLabel: "$t(companies.reaNumber.label)",
        tableHeader: "REA num",
        inputPlaceholder: "Enter REA number",
        filterInputPlaceholder: "Filter by REA number"
      },
      cuaa: {
        label: "CUAA",
        formLabel: "$t(companies.cuaa.label)",
        tableHeader: "$t(companies.cuaa.label)",
        inputPlaceholder: "Enter CUAA",
        filterInputPlaceholder: "Filter by CUAA"
      },
      tenant: {
        label: "Tenant",
        formLabel: "$t(companies.tenant.label)",
        inputPlaceholder: "Select client's tenant",
        name: {
          label: "$t(companies.tenant.label)",
          tableHeader: "$t(companies.tenant.label)",
          filterInputPlaceholder: "Filter by tenant"
        }
      },
      phoneNumber: {
        label: "Phone number",
        tableHeader: "$t(companies.phoneNumber.label)",
        formLabel: "$t(companies.phoneNumber.label)",
        inputPlaceholder: "Enter phone number",
        filterInputPlaceholder: "Filter by phone number"
      },
      email: {
        label: "Email address",
        tableHeader: "Email",
        formLabel: "$t(companies.email.label)",
        inputPlaceholder: "Enter email address",
        filterInputPlaceholder: "Filter by email"
      },
      certifiedEmail: {
        label: "Certified email",
        formLabel: "Certified email address",
        tableHeader: "Cert email",
        inputPlaceholder: "Enter certified email address",
        filterInputPlaceholder: "Filter by certified email"
      },
      registeredOfficeAddress: {
        label: "Reg address",
        fieldSetLabel: "",
        formLabel: "$t(companies.registeredOfficeAddress.label)",
        formatted: {
          label: "$t(companies.registeredOfficeAddress.label)",
          formLabel: "$t(companies.registeredOfficeAddress.label)",
          tableHeader: "$t(companies.registeredOfficeAddress.label)",
          filterInputPlaceholder: "Filtra per sede legale"
        },
        streetAddress: {
          formLabel: "Registration office address",
          inputPlaceholder: "$t(companies.address.streetAddress.inputPlaceholder)"
        },
        postalCode: {
          formLabel: "$t(companies.address.postalCode.formLabel)",
          inputPlaceholder: "$t(companies.address.postalCode.inputPlaceholder)"
        },
        locality: {
          formLabel: "$t(companies.address.locality.formLabel)",
          inputPlaceholder: "$t(companies.address.locality.inputPlaceholder)"
        },
        region: {
          formLabel: "$t(companies.address.region.formLabel)",
          inputPlaceholder: "$t(companies.address.region.inputPlaceholder)"
        },
        country: {
          formLabel: "$t(companies.address.country.formLabel)",
          inputPlaceholder: "$t(companies.address.country.inputPlaceholder)"
        },
        countryCode: {
          formLabel: "$t(companies.address.countryCode.formLabel)"
        }
      },
      headquartersAddress: {
        label: "HQ address",
        fieldSetLabel: "",
        formLabel: "$t(companies.headquartersAddress.label)",
        formatted: {
          label: "$t(companies.headquartersAddress.label)",
          formLabel: "$t(companies.headquartersAddress.label)",
          tableHeader: "$t(companies.headquartersAddress.label)",
          filterInputPlaceholder: "Filter by headquarter address"
        },
        streetAddress: {
          formLabel: "Headquarter's address",
          inputPlaceholder: "$t(companies.address.streetAddress.inputPlaceholder)"
        },
        postalCode: {
          formLabel: "$t(companies.address.postalCode.formLabel)",
          inputPlaceholder: "$t(companies.address.postalCode.inputPlaceholder)"
        },
        locality: {
          formLabel: "$t(companies.address.locality.formLabel)",
          inputPlaceholder: "$t(companies.address.locality.inputPlaceholder)"
        },
        region: {
          formLabel: "$t(companies.address.region.formLabel)",
          inputPlaceholder: "$t(companies.address.region.inputPlaceholder)"
        },
        country: {
          formLabel: "$t(companies.address.country.formLabel)",
          inputPlaceholder: "$t(companies.address.country.inputPlaceholder)"
        },
        countryCode: {
          formLabel: "$t(companies.address.countryCode.formLabel)"
        }
      },
      registeredOfficeEqualsHeadquartersAddress: {
        label: "Registration office address is headquarter address",
        formLabel: "",
        inputPlaceholder: "$t(companies.registeredOfficeEqualsHeadquartersAddress.label)"
      },
      updatedBy: {
        label: "Updated by",
        tableHeader: "$t(companies.updatedBy.label)",
        filterInputPlaceholder: "Filter by user"
      },
      updatedAt: {
        label: "Updated at",
        tableHeader: "$t(companies.updatedAt.label)",
        filterInputPlaceholder: "Filter by date"
      },
      createdBy: {
        label: "$t(createdBy.label)",
        tableHeader: "$t(createdBy.tableHeader)",
        filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
      },
      createdAt: {
        label: "$t(createdAt.label)",
        tableHeader: "$t(createdAt.tableHeader)",
        filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
      }
    },
    notifications: {
      label: "Notification",
      label_other: "Notifications",
      searchPlaceholder: "Search through notifications",
      filter: {
        label: "Filter by",
        mobileLabel: "Filters",
        dropdown: {
          unread: "Unread",
          read: "Read",
          info: "Info",
          warning: "Warning",
          alert: "Alert"
        }
      },
      sort: {
        label: "Sort by",
        mobileLabel: "Sort",
        dropdown: {
          desc: "Decreasing date",
          asc: "Ascending date"
        }
      },
      markAllAsRead: "Mark all as read"
    },
    management: {
      cultures: {
        label: "$t(cultures.label)",
        label_other: "$t(cultures.label_other)",
        createLabel: "$t(cultures.createLabel)",
        updateLabel: "$t(cultures.updateLabel)",
        id: {
          label: "id",
          tableHeader: "$t(management.cultures.id.label)"
        },
        cycleStartedAt: {
          label: "$t(cultures.cycleStartedAt.label)",
          tableHeader: "Cycle start date",
          formLabel: "$t(cultures.cycleStartedAt.formLabel)",
          inputPlaceholder: "$t(cultures.cycleStartedAt.inputPlaceholder)",
          filterInputPlaceholder: "Filter by cycle start date"
        },
        cycleEndedAt: {
          label: "$t(cultures.cycleEndedAt.label)",
          tableHeader: "End date",
          formLabel: "$t(cultures.cycleEndedAt.formLabel)",
          inputPlaceholder: "$t(cultures.cycleEndedAt.inputPlaceholder)",
          filterInputPlaceholder: "Filter by end date"
        },
        cultivatedCulture: {
          fieldSetLabel: "$t(cultures.cultivatedCulture.fieldSetLabel)",
          formLabel: "$t(cultures.cultivatedCulture.formLabel)",
          "name.en": {
            label: "Culture",
            tableHeader: "$t(management.cultures.cultivatedCulture.name.en.label)",
            filterInputPlaceholder: "Filter by culture"
          }
        },
        "cultivatedCultureVariety.name.en": {
          label: "Variety",
          tableHeader: "$t(management.cultures.cultivatedCultureVariety.name.en.label)",
          filterInputPlaceholder: "Filter by variety"
        },
        field: {
          label: "Field",
          formLabel: "$t(management.cultures.field.label)",
          inputPlaceholder: "Select field",
          name: {
            label: "$t(management.cultures.field.label)",
            tableHeader: "$t(management.cultures.field.label)",
            filterInputPlaceholder: "Filter by field"
          },
          "group.name": {
            label: "Field group",
            tableHeader: "$t(management.cultures.field.group.name.label)",
            filterInputPlaceholder: "Filter by field's group"
          },
          area: {
            label: "Field surface (m²)",
            tableHeader: "$t(management.cultures.field.area.label)"
          },
          areaHa: {
            label: "Field surface (ha)",
            tableHeader: "$t(management.cultures.field.areaHa.label)"
          }
        },
        area: {
          label: "Cultivated area percentage (%)",
          tableHeader: "$t(cultures.area.label) (%)",
          formLabel: "$t(management.cultures.area.label)",
          inputPlaceholder: "$t(cultures.area.inputPlaceholder)"
        },
        areaMq: {
          label: "$t(cultures.area.label) (m²)",
          tableHeader: "$t(cultures.area.label) (m²)"
        },
        areaHa: {
          label: "$t(cultures.area.label) (ha)",
          tableHeader: "$t(cultures.area.label) (ha)"
        },
        plantedUnits: {
          label: "$t(cultures.plantedUnits.label)",
          tableHeader: "$t(cultures.plantedUnits.label)",
          formLabel: "$t(cultures.plantedUnits.formLabel)",
          inputPlaceholder: "$t(cultures.plantedUnits.inputPlaceholder)"
        },
        experimental: {
          label: "Type",
          tableHeader: "Type",
          formLabel: "$t(cultures.experimental.formLabel)",
          inputPlaceholder: "$t(cultures.experimental.inputPlaceholder)",
          filterInputPlaceholder: "Filter by type"
        },
        status: {
          label: "$t(cultures.status.label)",
          tableHeader: "$t(cultures.status.label)",
          formLabel: "$t(cultures.status.label)",
          inputPlaceholder: "Select culture status",
          filterInputPlaceholder: "Filter by culture state"
        },
        tenant: {
          label: "Customer",
          formLabel: "$t(management.cultures.tenant.label)",
          inputPlaceholder: "Select customer",
          name: {
            label: "$t(management.cultures.tenant.label)",
            tableHeader: "$t(management.cultures.tenant.label)",
            filterInputPlaceholder: "Filter by customer"
          }
        },
        updatedBy: {
          label: "Updated by",
          tableHeader: "$t(management.cultures.updatedBy.label)",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated at",
          tableHeader: "$t(management.cultures.updatedAt.label)",
          filterInputPlaceholder: "Filter by updated at date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        }
      },
      fields: {
        label: "$t(fields.label)",
        label_other: "$t(fields.label_other)",
        createLabel: "$t(fields.createLabel)",
        updateLabel: "$t(fields.updateLabel)",
        exportShpLabel: "Download shape file",
        id: {
          label: "id",
          tableHeader: "$t(management.cultures.id.label)"
        },
        name: {
          label: "Name",
          tableHeader: "$t(management.fields.name.label)",
          formLabel: "$t(management.fields.name.label)",
          inputPlaceholder: "Enter field's name",
          filterInputPlaceholder: "Filter by field's name"
        },
        group: {
          label: "$t(fields.group.label)",
          formLabel: "$t(fields.group.formLabel)",
          inputPlaceholder: "$t(fields.group.inputPlaceHolder)",
          name: {
            label: "$t(fields.group.label)",
            tableHeader: "$t(fields.group.label)",
            filterInputPlaceholder: "Filter by field's group"
          }
        },
        area: {
          label: "$t(fields.area.tableHeader)",
          tableHeader: "$t(management.fields.area.label)",
          formLabel: "$t(management.fields.area.label)",
          inputPlaceholder: "Enter field's surface"
        },
        areaHa: {
          label: "Surface (ha)",
          tableHeader: "$t(management.fields.areaHa.label)"
        },
        address: {
          formatted: {
            label: "$t(fields.address.label)",
            tableHeader: "$t(fields.address.label)"
          }
        },
        "soilGranulometry.name.en": {
          label: "$t(fields.soilGranulometry.label)"
        },
        "coverageType.name.en": {
          label: "$t(fields.coverageType.label)"
        },
        "irrigation.method.name.en": {
          label: "$t(fields.irrigation.method.label)"
        },
        "irrigation.source.name.en": {
          label: "$t(fields.irrigation.source.label)"
        },
        "type.name.en": {
          label: "$t(fields.type.label)",
          tableHeader: "$t(fields.type.label)"
        },
        productionBatchCode: {
          label: "$t(fields.productionBatchCode.label)",
          tableHeader: "$t(fields.productionBatchCode.label)",
          filterInputPlaceholder: "$t(fields.productionBatchCode.filterInputPlaceholder)"
        },
        tenant: {
          label: "Customer",
          formLabel: "$t(management.cultures.tenant.label)",
          inputPlaceholder: "Seleziona cliente",
          name: {
            label: "$t(management.cultures.tenant.label)",
            tableHeader: "$t(management.cultures.tenant.label)",
            filterInputPlaceholder: "Filter by customer"
          }
        },
        updatedBy: {
          label: "Updated by",
          tableHeader: "$t(management.cultures.updatedBy.label)",
          filterInputPlaceholder: "Filter by user"
        },
        updatedAt: {
          label: "Updated at",
          tableHeader: "$t(management.cultures.updatedAt.label)",
          filterInputPlaceholder: "Filter by updated at date"
        },
        createdBy: {
          label: "$t(createdBy.label)",
          tableHeader: "$t(createdBy.tableHeader)",
          filterInputPlaceholder: "$t(createdBy.filterInputPlaceholder)"
        },
        createdAt: {
          label: "$t(createdAt.label)",
          tableHeader: "$t(createdAt.tableHeader)",
          filterInputPlaceholder: "$t(createdAt.filterInputPlaceholder)"
        },
        soilProperties: {
          soilTexture: {
            label: "$t(fields.soilProperties.soilTexture.formLabel)"
          },
          clay: {
            label: "$t(fields.soilProperties.clay.formLabel) (%Wt)"
          },
          sand: {
            label: "$t(fields.soilProperties.sand.formLabel) (%Wt)"
          },
          silt: {
            label: "Silt (%Wt)"
          },
          organicMatter: {
            label: "$t(fields.soilProperties.organicMatter.formLabel) (%Wt)"
          },
          compaction: {
            label: "$t(fields.soilProperties.compaction.formLabel)"
          },
          electricalConductivity: {
            label: "$t(fields.soilProperties.electricalConductivity.formLabel) dS/m"
          },
          gravel: {
            label: "$t(fields.soilProperties.gravel.formLabel) (%Wt)"
          },
          fieldCapacity: {
            label: "$t(fields.soilProperties.fieldCapacity.formLabel) (%Vol)"
          },
          wiltingPoint: {
            label: "$t(fields.soilProperties.wiltingPoint.formLabel) (%Vol)"
          },
          saturation: {
            label: "$t(fields.soilProperties.saturation.formLabel) (%Vol)"
          },
          availableWater: {
            label: "$t(fields.soilProperties.availableWater.formLabel) (%Vol)"
          },
          saturatedHydraulicConductivity: {
            label: "$t(fields.soilProperties.saturatedHydraulicConductivity.formLabel) (mm/h)"
          },
          bulkDensity: {
            label: "$t(fields.soilProperties.bulkDensity.formLabel) (g/cm³)"
          },
          ph: {
            label: "$t(fields.soilProperties.ph.formLabel)"
          },
          activeLimestone: {
            label: "$t(fields.soilProperties.activeLimestone.formLabel) (%Wt)"
          },
          potassiumOxide: {
            label: "$t(fields.soilProperties.potassiumOxide.formLabel) (%Wt)"
          },
          phosphoricAnhydride: {
            label: "$t(fields.soilProperties.phosphoricAnhydride.formLabel) (%Wt)"
          }
        }
      }
    }
  },
  maps: {
    geoAutocomplete: {
      placeholder: "Enter the address"
    },
    polygon: {
      clear: "Delete shapes"
    },
    layers: {
      menuLabel: "Add layers",
      overlaysHeader: "Show elements",
      fields: "Fields",
      devices: "Devices",
      cadastre: "Cadastral map",
      satIndexesHeader: "Satellitary indexes",
      satIndexImageDate: "Date",
      satIndex: "Index",
      satIndexNull: `There is no data for "{{index}}"`,
      satIndexReqError: "The selected layer cannot be shown, an error occurred in the request",
      ndvi: "Vegetation index (NDVI)",
      swir: "Soil water content (SWIR)",
      yield: "Potential yield (YIELD)",
      chlorophyll: "Nitrogen deficit",
      nitro: "Fertilizer (Compensation)"
    },
    drawings: {
      label: "Draw",
      edit: "Edit",
      delete: "Delete",
      confirm: "Confirm"
    }
  }
};
