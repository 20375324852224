/**
 * @author Alessandro Frenna
 * @description Context that is used to initialize keycloak auth provider
 */
import { useKeycloak } from "@react-keycloak/web";
import { axios } from "app/axios";
import { GlobalLoading } from "app/components/loading";
import { development } from "app/config";
import { AxiosError } from "axios";
import React, { createContext, useContext, useMemo } from "react";
import { useQuery } from "react-query";
import { proxy, useSnapshot } from "valtio";
import { devtools } from "valtio/utils";
import { hasRoles, Role } from "./roles";
import { useHistory } from "react-router-dom";
import { useEffectOnce } from "react-use";
import { loginOptions } from "./keycloak";

type AuthzState = {
  user: Record<string, any>;
};

const initialState: AuthzState = { user: undefined };
const authzState = proxy<AuthzState>(initialState);

type AuthzContextState = {
  readonly user: Record<string, any>;
  readonly hasRoles: (roles: Role[], all?: boolean) => boolean;
  readonly goToProfileManagementPage: () => void;
  readonly logout: () => void;
  readonly login: () => void;
};

type AuthzContextProviderProps = {
  readonly children: (props: { hasRoles: (roles: Role[], all: boolean) => boolean }) => React.ReactNode;
};

const AuthzContext = createContext<AuthzContextState>(undefined);

export const useAuthenticated = () => {
  return useContext(AuthzContext);
};

export const Authenticated: React.FC<AuthzContextProviderProps> = ({ children }): JSX.Element => {
  const { keycloak } = useKeycloak();
  const { push } = useHistory();
  const user = useSnapshot(authzState).user;

  const url = "profile/me";
  const { refetch: fetchProfile } = useQuery<Record<string, any>, AxiosError<any>>(
    [url],
    async () => {
      try {
        const { data } = await axios.get(url);
        return data;
      } catch (error) {
        const axiosError = error as AxiosError<any>;
        throw axiosError;
      }
    },
    {
      enabled: false,
      onSettled: (user: Record<string, any>, error: AxiosError<any>) => {
        if (error) keycloak.logout({ redirectUri: window.origin });
        authzState.user = user;
      }
    }
  );

  const memoizedHasRoles = useMemo(() => hasRoles(user, keycloak), [keycloak, user]);

  const logout = () => {
    keycloak.logout({ redirectUri: window.origin });
  };

  const login = () => {
    keycloak.login(loginOptions);
  };

  useEffectOnce(() => {
    if (!keycloak.authenticated) {
      login();
    } else if (!user) {
      fetchProfile();
    }
  });

  if (!user) return <GlobalLoading />;
  return (
    <AuthzContext.Provider
      value={{
        user,
        hasRoles: memoizedHasRoles,
        goToProfileManagementPage: () => {
          push("/profile", { from: `/profile`, fromLabel: "companies.goBackToProfile" });
        },
        logout,
        login
      }}
    >
      {children({ hasRoles: memoizedHasRoles })}
    </AuthzContext.Provider>
  );
};

development && devtools(authzState, { enabled: true, name: "AUTHZ_STATE" });
